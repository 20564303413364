import axios from "axios";

export async function fetchCards() {
  if (!window.localStorage.getItem("aide_token")) {
    return null;
  }

  let { data } = await axios.get("/widget/cards");
  return data.data.map((tax: any) => {
    return {
      label: tax.taxonomies,
      options: tax.cards.map((t: any) => {
        return {
          value: t.id,
          id: t.id,
          name: t.name,
          tab: t.name,
          label: <div data-testid="topic-option">{t.name}</div>,
          workflowActions: t.workflowActions
        };
      }).sort((cardA: any, cardB: any) => (Number(cardA.id) > Number(cardB.id) ? 1 : -1)),
    };
  }).sort((taxA: any, taxB: any) => (Number(taxA.label[0].id + taxA.label[1].id) > Number(taxB.label[0].id + taxB.label[1].id) ? 1 : -1));;
}
