// @ts-nocheck
import axios from "axios";

export async function fetchUser() {
  if (!window.localStorage.getItem("aide_token")) {
    return null;
  }

  let { data } = await axios.get("/widget/me");

  return data;
}
