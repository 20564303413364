// @ts-nocheck
import React, { useEffect } from "react";
import { useRouter } from "next/router";

import { useQuery } from "react-query";

import { useRecoilState } from "recoil";
import { currentTicketAtom } from "store/atoms/currentTicket";
import { navigationHistoryAtom } from "store/atoms/navigationHistory";
import { searchNavigationHistoryAtom } from "store/atoms/searchNavigationHistory";

import { useFrontContext } from "../providers/frontContext";

import axios from "axios";

import {
  fetchCards,
  fetchProcesses,
  fetchUser,
  fetchMacroActions,
  fetchMacros,
  fetchInboxes,
} from "queries/index";

import { useJune } from "hooks/june";

import { ZendeskPlatform } from "platforms/zendesk";

const withAuth = (DecoratedComponent: JSX.Element) => {
  // @ts-ignore
  const Hoc = (props) => {
    const zendesk = new ZendeskPlatform();
    const analytics = useJune();
    const frontContext = useFrontContext();
    const router = useRouter();
    const [navHistory, setNavHistory] = useRecoilState(navigationHistoryAtom);
    const [searchNavHistory, setSearchNavHistory] = useRecoilState(
      searchNavigationHistoryAtom
    );
    const [ticketId, setTicketId] = useRecoilState(currentTicketAtom);

    const { refetch, data: user } = useQuery("user", fetchUser);
    const { refetch: refetchCards } = useQuery("cards", fetchCards);
    const { refetch: refetchProcesses } = useQuery("processes", fetchProcesses);
    const { refetch: refetchMacros } = useQuery("macros", fetchMacros);
    const { refetch: refetchMacroActions } = useQuery(
      "macroActions",
      fetchMacroActions
    );
    const { refetch: refetchInboxes } = useQuery("inboxes", fetchInboxes);
    useEffect(() => {
      if (window.localStorage.getItem("aide_token")) {
        if (!window.location.pathname.includes("single-conversation")) {
          if (
            frontContext &&
            frontContext.type &&
            "front" === process.env.NEXT_PUBLIC_PLATFORM
          ) {
            if (frontContext.type === "noConversation") {
              return;
            }
          }
          getUser("/single-conversation");
        }
      }
    }, []);

    const shouldTrackUrl = (history, url) => {
      if (history.length > 0) {
        const lastUrl = [...history].reverse();

        if (lastUrl && url) {
          if (lastUrl[0].split("?")[0] == url.split("?")[0]) {
            return false;
          }
        }
      }

      return true;
    };

    useEffect(() => {
      const handleStart = (url: string) => {
        if (navHistory.url != url) {
          if (!url.includes("q=")) {
            if (shouldTrackUrl(navHistory.history, url)) {
              setNavHistory({
                url: navHistory.url,
                history: [...navHistory.history, url],
              });
            }
          } else {
            if (
              !url.includes("action=back") &&
              shouldTrackUrl(navHistory.history, url)
            ) {
              setSearchNavHistory({
                url: searchNavHistory.url,
                history: [...searchNavHistory.history, url],
              });
            }
          }
        }
      };

      router.events.on("routeChangeStart", handleStart);

      return () => {
        router.events.off("routeChangeStart", handleStart);
      };
    }, [router, navHistory, setSearchNavHistory]);

    useEffect(() => {
      if (!window.localStorage.getItem("aide_token")) {
        if (router.pathname !== "/login") {
          router.replace("/login");
        }
      }
    }, []);

    useEffect(() => {
      if (
        frontContext &&
        frontContext.type &&
        "front" === process.env.NEXT_PUBLIC_PLATFORM
      ) {
        if (window.localStorage.getItem("aide_token")) {
          switch (frontContext.type) {
            case "noConversation":
              getUser("/");
              break;
            case "singleConversation":
              if (!window.location.pathname.includes("single-conversation")) {
                getUser("/single-conversation");
              } else if (
                frontContext.conversation.id &&
                ticketId.current != frontContext.conversation.id
              ) {
                setTicketId({
                  previous: ticketId.current,
                  current: frontContext.conversation.id,
                });
                getUser("/single-conversation");
              }

              if (analytics) {
                analytics.track("Front Switched Conversation", {
                  id: frontContext.conversation.id,
                });
              }
              break;
          }
        }
      }
    }, [frontContext]);

    /**
     * Run interval to listen for token removal, i.e logout
     */
    useEffect(() => {
      let loginUpdate = setInterval(() => {
        const token = window.localStorage.getItem("aide_token");
        if (token) {
          if (router.pathname.includes("login")) {
            getUser("/single-conversation");
          }
        } else {
          router.replace("/login");
        }
      }, 10000);

      return () => clearInterval(loginUpdate);
    }, []);

    useEffect(() => {
      if (analytics && user) {
        analytics.identify(user.id, {
          name: user.name,
          email: user.email,
        });

        if (user.team) {
          analytics.group(user.team.name, {
            name: user.team.name,
            website: user.team?.website,
            ticket_count: user.team?.ticket_count,
            explore_status: user.team?.explore_status,
            show_onboarding: user.team?.show_onboarding,
            onboarding_stage: user.team?.onboarding_stage,
          });
        }
      }
    }, [user, analytics]);

    const nextPage = (nextPagePath) => {
      axios.get("/widget/me").then((response) => {
        if (response.data) {
          const resUser = response.data;

          if (resUser) {
            if (resUser.team) {
              if (process.env.NEXT_PUBLIC_PLATFORM) {
                if (resUser.integrations) {
                  const frontIntegration = resUser.integrations.find(
                    (i) => i.name === "front"
                  );

                  if (frontIntegration.active_integration) {
                    if (!frontIntegration.active_integration.is_enabled) {
                      return router.replace("/onboard/no-webhook");
                    }
                  }
                }
              }
            }

            router.replace(nextPagePath);
          }
        }
      });

      // router.replace(nextPagePath);
    };

    const getUser = (nextPagePath = "") => {
      if (!user) {
        refetch();
        refetchCards();
        refetchProcesses();
        refetchMacros();
        refetchMacroActions();
        refetchInboxes();

        nextPage(nextPagePath);
      } else if (nextPagePath) {
        nextPage(nextPagePath);
      }
    };

    // @ts-ignore
    return <DecoratedComponent {...props} />;
  };

  return Hoc;
};

export { withAuth };
