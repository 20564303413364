// @ts-nocheck
import { useEffect, useState } from "react";
import { AnalyticsBrowser } from "@june-so/analytics-next";

export function useJune() {
  const [analytics, setAnalytics] = useState(undefined);

  useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({
        writeKey:
          process.env.NODE_ENV === "production"
            ? process.env.NEXT_PUBLIC_JUNE_API_KEY
            : process.env.NEXT_PUBLIC_JUNE_API_KEY,
      });
      setAnalytics(response);
    };

    loadAnalytics();
  }, []);

  return analytics;
}
