import axios from "axios";

export async function fetchInboxes() {
  if (!window.localStorage.getItem("aide_token")) {
    return null;
  }

  let { data } = await axios.get(
    `/widget/inboxes?integration=${process.env.NEXT_PUBLIC_PLATFORM}`
  );
  return data;
}
