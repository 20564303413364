// @ts-nocheck

const client =
  typeof window !== "undefined" &&
  "zendesk" === process.env.NEXT_PUBLIC_PLATFORM
    ? window.ZAFClient.init()
    : null;

export class ZendeskPlatform {
  /**
   * Get tickets and send back
   *
   * @returns ticket
   */
  async getTicket() {
    let data;

    if (client) {
      try {
        let responseData = await client.get("ticket");

        if (responseData && "ticket" in responseData) {
          data = {
            requester: responseData["ticket"].requester,
            ticket: responseData["ticket"],
          };
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    return data;
  }

  /**
   * Append response into comment box
   * support inline HTML
   *
   * @param {*} text
   */
  async appendCommentHtml(text) {
    if (client) {
      client.invoke("ticket.comment.appendHtml", text);
    }
  }

  /**
   * Replace response into comment box
   * support inline HTML
   *
   * @param {*} text
   */
  async replaceCommentHtml(text) {
    if (client) {
      client.set("comment.text", text);
    }
  }

  async getComment() {
    if (client) {
      return await client.get("ticket.comment");
    }

    return "";
  }

  async applyMacro(macroId, textOverride=null) {
    if (client) {
      client.invoke("macro", macroId).then(() => {
        if (textOverride) {
          client.set('comment.text', textOverride)
        }
      })
    }
  }
}
