import axios from "axios";

export async function fetchMacroActions() {
  if (!window.localStorage.getItem("aide_token")) {
    return null;
  }

  let { data } = await axios.get("/widget/macros/actions");
  return data;
}
